<template>
    <div class="box">
        <!--  -->
         <div class="user-miniprogram">
            <h3 style="margin-left:80px;">用户端小程序 - 按钮组</h3>
            <div
                class="block"
                v-for="(item,index) in jgg"
                :key="index"
            >
                <upload :photo=item></upload>
                <div>{{item.des}}</div>
            </div>
        </div>
        <div class="user-miniprogram">
            <h3 style="margin-left:80px;">用户端小程序</h3>
            <div
                class="block"
                v-for="(item,index) in userphoto"
                :key="index"
            >
                <upload :photo=item></upload>
                <div>{{item.des}}</div>
            </div>
        </div>
        <!--  mch -->
        <div class="mch-miniprogram">
            <h3 style="margin-left:80px;">商户端小程序</h3>
            <div
                class="block"
                v-for="(item,index) in mchphoto"
                :key="index"
            >
                <upload :photo=item></upload>
                <div>{{item.des}}</div>
            </div>
        </div>
        <!-- pos -->
        <div class="mch-miniprogram">
            <h3 style="margin-left:80px;">POS机</h3>
            <div
                class="block"
                v-for="(item,index) in posphoto"
                :key="index"
            >
                <upload :photo=item></upload>
                <div>{{item.des}}</div>
            </div>
        </div>
        <!-- wm -->
         <div class="wm-miniprogram">
            <h3 style="margin-left:80px;">外卖</h3>
            <div
                class="block"
                v-for="(item,index) in wmphone"
                :key="index"
            >
                <upload :photo=item></upload>
                <div>{{item.des}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import upload from './components/Upload'
export default {
  name: '',
  data () {
    return {
      jgg: [
        {
          name: 'hym-bg',
          url: '',
          des: '会员码'
        },
        {
          name: 'wddd-bg',
          url: '',
          des: '我的订单'
        },
        {
          name: 'wdcz-bg',
          url: '',
          des: '我的储值'
        },
        {
          name: 'yhj-bg',
          url: '',
          des: '优惠券'
        }, {
          name: 'jfsc-bg',
          url: '',
          des: '积分商城'
        }, {
          name: 'grzl-bg',
          url: '',
          des: '个人资料'
        }, {
          name: 'yjfk-bg',
          url: '',
          des: '意见反馈'
        },
        {
          name: 'wm-bg',
          url: '',
          des: '外卖'
        }
      ],
      userphoto: [
        {
          name: 'member-bg',
          url: '',
          des: '会员背景'
        },
        {
          name: 'member-rule-bg',
          url: '',
          des: '会员积分规则'
        },
        {
          name: 'integral-bg',
          url: '',
          des: '积分商城背景'
        },
        {
          name: 'money-bg',
          url: '',
          des: '储值背景'
        },
        {
          name: 'code-member-bg',
          url: '',
          des: '会员码背景图'
        },
        {
          name: 'code-member-logo',
          url: '',
          des: '会员码背景logo'
        },
        {
          name: 'member-money-bg',
          url: '',
          des: '储值活动按钮'
        },
        {
          name: 'no-data',
          url: '',
          des: '空状态图标'
        },
        {
          name: 'icon_lbs',
          url: '',
          des: '地址图标'
        },
        {
          name: 'shopping-one',
          url: '',
          des: '店内就餐图片'
        },
        {
          name: 'shopping-two',
          url: '',
          des: '打包图片'
        },
        {
          name: 'shopping-three',
          url: '',
          des: '外卖就餐图片'
        },
        {
          name: 'coupon-pt-bg',
          url: '',
          des: '普通优惠劵'
        },
        {
          name: 'coupon-bg',
          url: '',
          des: '优惠劵'
        },
        {
          name: 'coupon-use',
          url: '',
          des: '优惠劵已使用'
        },
        {
          name: 'coupon-no-use',
          url: '',
          des: '优惠劵已过期'
        },
        {
          name: 'coupon-overde',
          url: '',
          des: '优惠劵无法使用'
        },
        {
          name: 'integral-one',
          url: '',
          des: '礼劵兑换'
        },
        {
          name: 'integral-two',
          url: '',
          des: '兑换专区'
        },
        {
          name: 'exchange-ok',
          url: '',
          des: '兑换成功'
        },
        {
          name: 'exchange-error',
          url: '',
          des: '兑换失败'
        },
        {
          name: 'plus-one',
          url: '',
          des: '不可以减'
        },
        {
          name: 'plus-two',
          url: '',
          des: '可以减'
        },
        {
          name: 'minus-one',
          url: '',
          des: '不可以加'
        },
        {
          name: 'minus-grey',
          url: '',
          des: '不可以加(灰色的加)'
        },
        {
          name: 'minus-two',
          url: '',
          des: '可以加'
        },
        {
          name: 'cart-one',
          url: '',
          des: '购物车小图片'
        },

        {
          name: 'star-grey',
          url: '',
          des: '星星未选'
        },
        {
          name: 'star-ok',
          url: '',
          des: '星星已选'
        },
        {
          name: 'feedback-ok',
          url: '',
          des: '反馈成功'
        },
        {
          name: 'no-data',
          url: '',
          des: '无数据'
        }
      ],
      mchphoto: [
        {
          name: 'mch-log',
          url: '',
          des: 'Logo'
        }
      ],
      posphoto: [
        {
          name: 'login-cover',
          des: '启动页面背景'
        },
        {
          name: 'f-screen',
          des: '副屏页面背景'
        }
      ],
      wmphone: [
        {
          name: 'wm-cc0',
          url: '',
          des: '外卖车车灰色'
        },
        {
          name: 'wm-cc1',
          url: '',
          des: '外卖车车亮色'
        },
        {
          name: 'wm-sd-bg',
          url: '',
          des: '外卖预计送达时间小圆圈'
        }, {
          name: 'wm-g-i',
          url: '',
          des: '送餐地址勾选-未勾选状态'
        },
        {
          name: 'wm-g-ii',
          url: '',
          des: '送餐地址勾选-已勾选状态'
        },
        {
          name: 'wm-red-l',
          url: '',
          des: '地址选定-下拉箭头'
        }
      ]
    }
  },

  components: { upload }
}
</script>
<style lang='scss' scoped>
.wm-miniprogram{
  background: #999;
}
</style>
